import React from "react"

const ImparablesShort = () => (
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 597.5 269">
    <g>
      <path fill="currentColor" d="M192.9,261.8L204.7,6c0.3-1.2,1.1-1.9,2.4-1.9H241c1.6,0,2.4,0.6,2.4,1.9l11.8,255.7c0,1.9-0.8,2.8-2.4,2.8
        h-20.2c-1.6,0-2.4-0.6-2.4-1.9l-0.9-67.4h-10.5l-0.9,67.4c0,1.2-0.8,1.9-2.4,1.9h-20.2C194,264.5,192.9,263.7,192.9,261.8z
        M226.1,39.6h-4.2l-3.7,132.3h11.6L226.1,39.6z"/>
      <path fill="currentColor" d="M0.9,262.2V6.5c0-1.5,0.8-2.3,2.4-2.3H23c1.6,0,2.4,0.8,2.4,2.3v255.7c0,1.5-0.8,2.3-2.4,2.3H3.2
        C1.7,264.5,0.9,263.8,0.9,262.2z"/>
      <path fill="currentColor" d="M184.4,12.5c4.7,5.6,7.1,14.4,7.1,26.4v92.9c0,12-2.4,20.8-7.3,26.4c-4.9,5.6-12.6,8.3-23.3,8.3h-0.9v95.7
        c0,1.5-0.8,2.3-2.4,2.3h-19.8c-1.6,0-2.4-0.8-2.4-2.3V6.5c0-1.5,0.8-2.3,2.4-2.3h23.1C171.9,4.2,179.7,6.9,184.4,12.5z
        M166.1,144.5c0.6-1.1,0.9-2.5,0.9-4.4s0-3.2,0-4.2V34.3v-2.8c0-1.9-0.4-3.3-1.2-4.4c-0.8-1.1-2.4-1.6-4.9-1.6h-0.9v120.6h0.9
        C163.7,146.1,165.4,145.6,166.1,144.5z"/>
      <path fill="currentColor" d="M323.6,261.8L335.4,6c0.3-1.2,1.1-1.9,2.4-1.9h33.9c1.6,0,2.4,0.6,2.4,1.9l11.8,255.7c0,1.9-0.8,2.8-2.4,2.8
        h-21.2c-1.6,0-2.4-0.6-2.4-1.9l-0.9-67.4h-8.5l-0.9,67.4c0,1.2-0.8,1.9-2.4,1.9H326C324.7,264.5,323.6,263.7,323.6,261.8z
        M356.8,39.6h-4.2l-2.7,132.3h9.6L356.8,39.6z"/>
      <path fill="currentColor" d="M320.1,261.3c0,2.2-0.9,3.2-2.8,3.2h-19.8c-1.3,0-2-0.6-2.4-1.9c-0.6-3.1-1.2-8.1-1.6-15
        c-0.5-6.9-0.7-12.7-0.7-17.4V151v-3.2c0-2.8-0.3-4.7-0.9-5.8c-0.6-1.1-1.9-1.6-3.8-1.6h-2.4v121.9c0,1.5-0.8,2.3-2.4,2.3h-19.8
        c-1.6,0-2.4-0.8-2.4-2.3V6.5c0-1.5,0.8-2.3,2.4-2.3h24.5c10.4,0,17.8,2.8,22.4,8.3c4.5,5.6,6.8,14.4,6.8,26.4v64.9
        c0,14.5-4.7,22.8-14.1,25v1.9c5,0.6,8.6,2.5,10.8,5.6c2.2,3.1,3.3,8.3,3.3,15.7v67.7C317.3,238.8,318.3,252.7,320.1,261.3z
        M292.1,118.4c0.5-1.4,0.7-3.8,0.7-7.2V33.4v-1.9c0-4-1.6-6-4.7-6h-2.4v94.9h2.4C290.3,120.5,291.6,119.8,292.1,118.4z"/>
      <path fill="currentColor" d="M443.6,121.4c-3,4.3-6.8,6.8-11.5,7.4v1.9c10.7,0.6,16,7.4,16,20.4v80.6c0,12-2.3,20.5-6.8,25.5
        c-4.6,4.9-12.6,7.4-24.3,7.4h-22.6c-1.6,0-2.4-0.8-2.4-2.3V6.5c0-1.5,0.8-2.3,2.4-2.3H417c10.7,0,18.5,2.5,23.6,7.6
        c5,5.1,7.5,13.5,7.5,25.2v67.7C448.1,111.5,446.6,117.1,443.6,121.4z M423.6,33.4c0-3.1-0.4-5.2-1.2-6.3c-0.8-1.1-2.6-1.6-5.4-1.6
        h-0.5v94.9c3.5-0.3,5.5-1.2,6.1-2.8c0.6-1.5,0.9-4.3,0.9-8.3V35.7V33.4z M422.6,242.3c0.6-1.2,0.9-4,0.9-8.3v-82v-2.3
        c0-3.1-0.4-5.3-1.2-6.7c-0.8-1.4-2.8-2.2-5.9-2.5v103.8h0.5C420.1,244.2,422,243.5,422.6,242.3z"/>
      <path fill="currentColor" d="M492.4,242.3v19.9c0,1.5-0.8,2.3-2.4,2.3h-31.6c-1.6,0-2.4-0.8-2.4-2.3V6.5c0-1.5,0.8-2.3,2.4-2.3h19.8
        c1.6,0,2.4,0.8,2.4,2.3V240h9.4C491.6,240,492.4,240.8,492.4,242.3z"/>
      <path fill="currentColor" d="M521.5,28.7v91.8h11.4c1.6,0,2.4,0.6,2.4,1.9v16.2c0,1.2-0.8,1.9-2.4,1.9h-11.4V240h11.4
        c1.6,0,2.4,0.8,2.4,2.3v19.9c0,1.5-0.8,2.3-3.4,2.3h-32.5c-0.6,0-1.3-0.8-1.3-2.3V6.5c0-1.5,0.8-2.3,1.3-2.3h32.5
        c2.6,0,3.4,0.8,3.4,2.3v19.9c0,1.5-0.8,2.3-2.4,2.3H521.5z"/>
      <path fill="currentColor" d="M548.4,258.5c-4.7-4.6-7.1-12.7-7.1-24.1V153c0-1.5,0.8-2.3,2.4-2.3H563c1.6,0,2.4,0.8,2.4,2.3v85.1
        c0,1.9,0.2,3.4,0.7,4.6c0.5,1.2,1.5,1.9,3.1,1.9c1.6,0,2.6-0.6,3.1-1.9c0.5-1.2,0.7-2.8,0.7-4.6v-69c0-9.3-1.2-16-3.5-20.4
        c-2.4-4.3-5.9-8.2-10.6-11.6c-6.9-4.9-11.5-9.8-13.9-14.6c-2.4-4.8-3.5-12.6-3.5-23.4V34.3c0-11.4,2.4-19.4,7.1-24.1
        c4.7-4.6,11.6-6.9,20.7-6.9c9.1,0,16,2.3,20.7,6.9c4.7,4.6,7.1,12.7,7.1,24.1v72.6c0,1.5-0.8,2.3-2.4,2.3h-19.3
        c-1.6,0-2.4-0.8-2.4-2.3V30.6c0-2.2-0.2-3.8-0.7-4.9c-0.5-1.1-1.5-1.6-3.1-1.6c-1.6,0-2.6,0.5-3.1,1.6c-0.5,1.1-0.7,2.7-0.7,4.9
        v62.5c0,7.7,1.1,13.3,3.3,16.7c2.2,3.4,5.8,7.3,10.8,11.6c6.9,5.3,11.5,10.6,13.9,16c2.4,5.4,3.5,14,3.5,25.7v71.4
        c0,11.4-2.4,19.4-7.1,24.1c-4.7,4.6-11.6,6.9-20.7,6.9C560.1,265.5,553.2,263.1,548.4,258.5z"/>
      <path fill="currentColor" d="M90.1,264.5c1.6,0,2.4-0.6,2.4-1.9l10.1-222.9h5.6l-8.3,222.9c0,1.2,0.8,1.9,2.4,1.9h20.4
        c1.6,0,2.4-0.9,2.4-2.8V6c0-1.2-0.8-1.9-2.4-1.9H90.9c-1.6,0-2.4,0.6-2.4,1.9l-7.2,199.1H79L71.8,6c0-1.2-0.8-1.9-2.4-1.9H37.7
        c-1.6,0-2.4,0.6-2.4,1.9v255.7c0,1.9,0.8,2.8,2.4,2.8h20.4c1.6,0,2.4-0.6,2.4-1.9L52.1,39.8h5.6l10.2,222.9c0,1.2,0.8,1.9,2.4,1.9
        H90.1z"/>
    </g>
  </svg>
)

export default ImparablesShort
